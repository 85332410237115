import "./scss/app.scss";
import "swiper/swiper-bundle.css";

import Vue from "vue";

import {
  createPinia,
  PiniaVuePlugin,
} from "pinia";
import {
  Autoplay,
  Grid,
  Mousewheel,
  Navigation,
  Pagination,
  Controller,
  Swiper as SwiperClass,
} from "swiper/swiper.esm";
import getAwesomeSwiper from "vue-awesome-swiper/dist/exporter";
import VueCookies from "vue-cookies";
import { InlineSvgPlugin } from "vue-inline-svg";
import VueScreenSize from "vue-screen-size";
import VueSmoothScroll from "vue2-smooth-scroll";
import Vuelidate from "vuelidate";

import registerVueDirectives from "./directives";
import UwsPassport from "./services/UwsPassport";

Vue.config.productionTip = false;
registerVueDirectives();
SwiperClass.use([Pagination, Mousewheel, Autoplay, Navigation, Grid, Controller]);
Vue.use(getAwesomeSwiper(SwiperClass));
Vue.use(Vuelidate);
Vue.use(VueScreenSize);
Vue.use(VueCookies);
Vue.use(InlineSvgPlugin);
Vue.use(VueSmoothScroll);

const files = require.context("./components", true, /\.vue$/i);
files.keys().map(key =>
  Vue.component(
    key
      .split("/")
      .pop()
      .split(".")[0],
    files(key).default
  )
);

// UWS
const {
  uwsPassport: {
    base: uwsPassportBaseUrl,
    version: uwsPassportVersion,
    projectId
  }
} = window.UWS_SETTINGS;

const UwsPassportService = new UwsPassport(uwsPassportBaseUrl, uwsPassportVersion, projectId , {
  onGetTokenSuccess: ({jwt}) => {
    $vue.uwsUser.isAuthorized = jwt.length > 0;
  },
  onSignInSuccess: ({jwt}) => {
    $vue.uwsUser.isAuthorized = jwt.length > 0;
  },
  onSignUpSuccess: async ({jwt}) => {
    $vue.uwsUser.isAuthorized = jwt.length > 0;
  },
});

// Event Bus
export const eventBus = new Vue();

//Components store
Vue.use(PiniaVuePlugin);
const pinia = createPinia();

// Init
const $vue = new Vue({
  el: '#app',
  comments: true,
  data() {
    return {
      showCookieAlert: false,
      showSiteAlert: true,
      showCatalogAlert: true,
      siteAlertChecked: false,
      sideMenuOpened: false,
      onTop: true,
      uwsUser: {
        isAuthorized: false,
      },
    };
  },
  provide() {
    return {
      uwsUser: this.uwsUser
    }
  },
  computed: {
    isTablet: function () {
      return this.$vssWidth < 1024
    },
  },
  created() {
    window.addEventListener('scroll', this.handleScroll);
  },
  mounted() {
    this.checkCookies();
    UwsPassportService.getWidget();
    eventBus.$on("signInUws", this.signInUws);
    eventBus.$on("signUpUws", this.signUpUws);
    eventBus.$on("logoutUws", this.logoutUws);
  },
  methods: {
    sideMenuOpen() {
      this.sideMenuOpened = !this.sideMenuOpened;

      if (this.sideMenuOpened) {
        document.documentElement.classList.toggle('side-menu-opened');
      } else {
        document.documentElement.classList.toggle('side-menu-opened');
      }
    },

    checkCookies() {
      if (this.$cookies.get('cookieAlertHidden') === '1') {
        this.showCookieAlert = false;
      }
      if (this.$cookies.get('siteAlertHidden') === '1') {
        this.showSiteAlert = false;
      }
      if (sessionStorage.getItem('showCatalogAlert')) {
        this.showCatalogAlert = false;
      }
    },
    setCookie(name) {
      this.$cookies.set(name, '1', '1y');
    },
    closeSiteAlert() {
      this.setCookie('siteAlertHidden');
      this.siteAlertChecked = true;
      setTimeout(() => this.showSiteAlert = false, 1000)
    },
    closeCatalogAlert() {
      this.showCatalogAlert = false;
      sessionStorage.setItem('showCatalogAlert', 1);
    },
    handleScroll() {
      this.onTop = window.scrollY < 10 ? true : false;
    },
    scrollTop() {
      window.scrollTo(0,0);
    },
    async signInUws() {
      const widget = await UwsPassportService.getWidget();

      if (!this.uwsUser.isAuthorized) {
        widget.signInWithUI();
      }
    },
    async signUpUws() {
      const widget = await UwsPassportService.getWidget();

      if (!this.uwsUser.isAuthorized) {
        widget.signUpWithUI();
      }
    },
    async logoutUws() {
      const widget = await UwsPassportService.getWidget();
      const onSuccess = () => this.uwsUser.isAuthorized = false;
      const onFailure = () => this.uwsUser.isAuthorized = false;

      if (this.uwsUser.isAuthorized) {
        widget.signOut(onSuccess, onFailure);
      }
    },
  },
  pinia
});

if (module.hot) {
  try {
    const replacementsContext = require.context('../s1/replacements', true, /\.html/);
    replacementsContext.keys().forEach((key) => {
      replacementsContext(key);
    });
  } catch (e) {/**/}
}
