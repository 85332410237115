import axios from "axios";
import { defineStore } from "pinia";

const RATING_KEY = 'ratings';

export const useRecipesRatingStore = defineStore('recipes-rating', {
  state: () => ({
    isLoading: false,
    ratings: [],
    userRatings: JSON.parse(localStorage.getItem(RATING_KEY) || '{}'),
  }),
  getters: {
  },
  actions: {
    async getRating({ id }) {
      const fetchUrl = `/bitrix/services/main/ajax.php?action=bstd:rating.api.bstdratingcontroller.getaverage&entity_id=${ id }`;
      try {
        const response = await axios.get(fetchUrl);

        if(response.data.status === 'success') {
         this.ratings = {
           ...this.ratings,
           [id]: response.data.data
         };
        } else {
          console.log(response.data.errors[0].message);
        }
      } catch (error) {
        console.log(error);
      }
    },
    async setRating({ id, value }) {
      const fetchUrl = `/bitrix/services/main/ajax.php?action=bstd:rating.api.bstdratingcontroller.addvote&entity_id=${ id }&value=${ value }`;

      try {
        this.isLoading = true;

        const response = await axios.get(fetchUrl);

        if(response.data.status === 'success') {
          this.ratings = {
            ...this.ratings,
            [id]: response.data.data
          };

          this.userRatings = {
            ...this.userRatings,
            [id]: value,
          };

          localStorage.setItem(RATING_KEY, JSON.stringify(this.userRatings));
        } else {
          console.log(response.data.errors[0].message);
        }
      }
      catch (error) {
        console.log(error);
      }
      finally {
        this.isLoading = false;
      }
    },
    async cancelRating({ id }) {
      const fetchUrl = `/bitrix/services/main/ajax.php?action=bstd:rating.api.bstdratingcontroller.deletevote&entity_id=${ id }`;

      try {
        this.isLoading = true;
        const response = await axios.get(fetchUrl);

        if(response.data.status === 'success') {
          this.ratings = {
            ...this.ratings,
            [id]: response.data.data
          };

          this.userRatings[id] = null;
          localStorage.setItem(RATING_KEY, JSON.stringify(this.userRatings));
        } else {
          console.log(response.data.errors[0].message);
        }
      }
      catch (error) {
        console.log(error);
      }
      finally {
        this.isLoading = false;
      }
    }
  },
})
