<template>
  <div class="additional-nutritional" v-if="dataArray.length > 0">
    <div class="additional-nutritional__holder">
      <table>
        <thead>
          <tr>
            <th>{{ head.COL_1 }}</th>
            <th v-if="showThirdColumn">
              <span>{{ head.COL_3 }}</span>
            </th>
            <th :class="[{ 'td-right': !showThirdColumn }]">
              <!-- <span>{{ head.COL_2 }}</span> -->
            </th>
          </tr>
        </thead>
        <tbody>
          <template v-for="(item, index) in dataArray">
            <tr :key="`row${index}`" v-if="opened || index < 5">
              <td>
                <template>{{ item.COL_1 }}</template>
              </td>
              <td v-if="showThirdColumn">
                <b>
                  <template
                    ><span>{{ item.COL_3 }}</span></template
                  >
                </b>
              </td>
              <td :class="[{ 'td-right': !showThirdColumn }]">
                <b>
                  <template
                    ><span>{{ item.COL_2 }}</span></template
                  >
                </b>
              </td>
            </tr>
          </template>
        </tbody>
      </table>
    </div>
    <div
      @click="showMore"
      v-if="dataArray.length > 5"
      :class="[
        'additional-nutritional__more',
        { 'additional-nutritional__more--active': opened },
      ]"
    >
      <span>
        Показать еще
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 12 7" fill="none">
          <path
            d="M0.999511 1.00043L5.99951 6.00043L10.9995 1.00043"
            stroke="#00B5ED"
            stroke-width="2"
            stroke-linecap="round"
            stroke-linejoin="round"
          />
        </svg>
      </span>
      <span>
        Скрыть
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16 16" fill="none">
          <path
            d="M3 11.0013L8 6.00134L13 11.0013"
            stroke="#00B5ED"
            stroke-width="2"
            stroke-linecap="round"
            stroke-linejoin="round"
          />
        </svg>
      </span>
    </div>
  </div>
</template>

<script>
export default {
  name: "AdditionalNutritional",
  props: {
    dataArray: {
      type: Array,
      default: () => [],
    },
    head: {
      type: Object,
    },
  },
  data() {
    return {
      opened: false,
      showThirdColumn: false,
    };
  },
  computed: {},
  created() {},

  mounted() {
    this.checkColumns();
  },

  methods: {
    showMore() {
      this.opened = !this.opened;
    },

    resizeHandler() {},

    checkColumns() {
      if (this.dataArray) {
        for (let i in this.dataArray) {
          if (this.dataArray[i]["COL_3"].length > 0) {
            this.showThirdColumn = true;
            break;
          }
        }
      }
    },
  },
};
</script>
