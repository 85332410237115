<template>
  <div :class="['article-rating', {'article-rating_state_active': isActive}, {'article-rating_state_load': isLoading}]">
    <div class="article-rating-list" v-if="view === 'list'">
      <p class="article-rating-list__count">{{ average }}</p>
      <div class="article-rating-list__icon">
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="none"><path fill="var(--fill)" d="M9.153 5.208C10.42 2.936 11.053 1.8 12 1.8c.947 0 1.58 1.136 2.847 3.408l.328.588c.36.646.54.969.82 1.182.28.213.63.292 1.33.45l.636.144c2.46.556 3.689.835 3.982 1.776.292.94-.546 1.92-2.223 3.882l-.434.507c-.476.557-.715.836-.822 1.18-.107.345-.071.717.001 1.46l.066.677c.253 2.616.38 3.925-.386 4.506-.766.582-1.918.051-4.22-1.009l-.597-.274c-.654-.302-.981-.452-1.328-.452-.347 0-.674.15-1.329.452l-.595.274c-2.303 1.06-3.455 1.59-4.22 1.009-.767-.581-.64-1.89-.387-4.506l.066-.677c.072-.743.108-1.115 0-1.46-.106-.344-.345-.623-.821-1.18l-.434-.507c-1.677-1.961-2.515-2.942-2.223-3.882.293-.941 1.523-1.22 3.983-1.776l.636-.144c.699-.158 1.048-.237 1.329-.45.28-.213.46-.536.82-1.182l.328-.588Z"/></svg>
      </div>
    </div>
    <div class="article-rating-value" v-if="view === 'card'">
      <p class="article-rating-value__count">{{ average }}</p>
      <div class="article-rating-value__icon">
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="none"><path fill="var(--fill)" d="M9.153 5.208C10.42 2.936 11.053 1.8 12 1.8c.947 0 1.58 1.136 2.847 3.408l.328.588c.36.646.54.969.82 1.182.28.213.63.292 1.33.45l.636.144c2.46.556 3.689.835 3.982 1.776.292.94-.546 1.92-2.223 3.882l-.434.507c-.476.557-.715.836-.822 1.18-.107.345-.071.717.001 1.46l.066.677c.253 2.616.38 3.925-.386 4.506-.766.582-1.918.051-4.22-1.009l-.597-.274c-.654-.302-.981-.452-1.328-.452-.347 0-.674.15-1.329.452l-.595.274c-2.303 1.06-3.455 1.59-4.22 1.009-.767-.581-.64-1.89-.387-4.506l.066-.677c.072-.743.108-1.115 0-1.46-.106-.344-.345-.623-.821-1.18l-.434-.507c-1.677-1.961-2.515-2.942-2.223-3.882.293-.941 1.523-1.22 3.983-1.776l.636-.144c.699-.158 1.048-.237 1.329-.45.28-.213.46-.536.82-1.182l.328-.588Z"/></svg>
      </div>
    </div>
    <div class="article-rating__body" v-if="view === 'control'">
      <div class="article-rating-grade" v-if="userSet">
        <div class="article-rating-grade__body">
          <p class="article-rating-grade__count">{{ userSet }}</p>
          <div class="article-rating-grade__icon">
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="none"><path fill="var(--fill)" d="M9.153 5.208C10.42 2.936 11.053 1.8 12 1.8c.947 0 1.58 1.136 2.847 3.408l.328.588c.36.646.54.969.82 1.182.28.213.63.292 1.33.45l.636.144c2.46.556 3.689.835 3.982 1.776.292.94-.546 1.92-2.223 3.882l-.434.507c-.476.557-.715.836-.822 1.18-.107.345-.071.717.001 1.46l.066.677c.253 2.616.38 3.925-.386 4.506-.766.582-1.918.051-4.22-1.009l-.597-.274c-.654-.302-.981-.452-1.328-.452-.347 0-.674.15-1.329.452l-.595.274c-2.303 1.06-3.455 1.59-4.22 1.009-.767-.581-.64-1.89-.387-4.506l.066-.677c.072-.743.108-1.115 0-1.46-.106-.344-.345-.623-.821-1.18l-.434-.507c-1.677-1.961-2.515-2.942-2.223-3.882.293-.941 1.523-1.22 3.983-1.776l.636-.144c.699-.158 1.048-.237 1.329-.45.28-.213.46-.536.82-1.182l.328-.588Z"/></svg>
          </div>
        </div>
        <button class="article-rating-grade__remove" @click="cancelRating">
          <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 14 14" fill="none"><path stroke="#005DAA" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M13 1 1 13M1 1l12 12"/></svg>
        </button>
      </div>
      <div class="article-rating__list" v-else>
        <div class="article-rating__item" @click="setRating({value: 5})">
          <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="none"><path fill="var(--fill)" d="M9.153 5.208C10.42 2.936 11.053 1.8 12 1.8c.947 0 1.58 1.136 2.847 3.408l.328.588c.36.646.54.969.82 1.182.28.213.63.292 1.33.45l.636.144c2.46.556 3.689.835 3.982 1.776.292.94-.546 1.92-2.223 3.882l-.434.507c-.476.557-.715.836-.822 1.18-.107.345-.071.717.001 1.46l.066.677c.253 2.616.38 3.925-.386 4.506-.766.582-1.918.051-4.22-1.009l-.597-.274c-.654-.302-.981-.452-1.328-.452-.347 0-.674.15-1.329.452l-.595.274c-2.303 1.06-3.455 1.59-4.22 1.009-.767-.581-.64-1.89-.387-4.506l.066-.677c.072-.743.108-1.115 0-1.46-.106-.344-.345-.623-.821-1.18l-.434-.507c-1.677-1.961-2.515-2.942-2.223-3.882.293-.941 1.523-1.22 3.983-1.776l.636-.144c.699-.158 1.048-.237 1.329-.45.28-.213.46-.536.82-1.182l.328-.588Z"/></svg>
        </div>
        <div class="article-rating__item" @click="setRating({value: 4})">
          <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="none"><path fill="var(--fill)" d="M9.153 5.208C10.42 2.936 11.053 1.8 12 1.8c.947 0 1.58 1.136 2.847 3.408l.328.588c.36.646.54.969.82 1.182.28.213.63.292 1.33.45l.636.144c2.46.556 3.689.835 3.982 1.776.292.94-.546 1.92-2.223 3.882l-.434.507c-.476.557-.715.836-.822 1.18-.107.345-.071.717.001 1.46l.066.677c.253 2.616.38 3.925-.386 4.506-.766.582-1.918.051-4.22-1.009l-.597-.274c-.654-.302-.981-.452-1.328-.452-.347 0-.674.15-1.329.452l-.595.274c-2.303 1.06-3.455 1.59-4.22 1.009-.767-.581-.64-1.89-.387-4.506l.066-.677c.072-.743.108-1.115 0-1.46-.106-.344-.345-.623-.821-1.18l-.434-.507c-1.677-1.961-2.515-2.942-2.223-3.882.293-.941 1.523-1.22 3.983-1.776l.636-.144c.699-.158 1.048-.237 1.329-.45.28-.213.46-.536.82-1.182l.328-.588Z"/></svg>
        </div>
        <div class="article-rating__item" @click="setRating({value: 3})">
          <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="none"><path fill="var(--fill)" d="M9.153 5.208C10.42 2.936 11.053 1.8 12 1.8c.947 0 1.58 1.136 2.847 3.408l.328.588c.36.646.54.969.82 1.182.28.213.63.292 1.33.45l.636.144c2.46.556 3.689.835 3.982 1.776.292.94-.546 1.92-2.223 3.882l-.434.507c-.476.557-.715.836-.822 1.18-.107.345-.071.717.001 1.46l.066.677c.253 2.616.38 3.925-.386 4.506-.766.582-1.918.051-4.22-1.009l-.597-.274c-.654-.302-.981-.452-1.328-.452-.347 0-.674.15-1.329.452l-.595.274c-2.303 1.06-3.455 1.59-4.22 1.009-.767-.581-.64-1.89-.387-4.506l.066-.677c.072-.743.108-1.115 0-1.46-.106-.344-.345-.623-.821-1.18l-.434-.507c-1.677-1.961-2.515-2.942-2.223-3.882.293-.941 1.523-1.22 3.983-1.776l.636-.144c.699-.158 1.048-.237 1.329-.45.28-.213.46-.536.82-1.182l.328-.588Z"/></svg>
        </div>
        <div class="article-rating__item" @click="setRating({value: 2})">
          <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="none"><path fill="var(--fill)" d="M9.153 5.208C10.42 2.936 11.053 1.8 12 1.8c.947 0 1.58 1.136 2.847 3.408l.328.588c.36.646.54.969.82 1.182.28.213.63.292 1.33.45l.636.144c2.46.556 3.689.835 3.982 1.776.292.94-.546 1.92-2.223 3.882l-.434.507c-.476.557-.715.836-.822 1.18-.107.345-.071.717.001 1.46l.066.677c.253 2.616.38 3.925-.386 4.506-.766.582-1.918.051-4.22-1.009l-.597-.274c-.654-.302-.981-.452-1.328-.452-.347 0-.674.15-1.329.452l-.595.274c-2.303 1.06-3.455 1.59-4.22 1.009-.767-.581-.64-1.89-.387-4.506l.066-.677c.072-.743.108-1.115 0-1.46-.106-.344-.345-.623-.821-1.18l-.434-.507c-1.677-1.961-2.515-2.942-2.223-3.882.293-.941 1.523-1.22 3.983-1.776l.636-.144c.699-.158 1.048-.237 1.329-.45.28-.213.46-.536.82-1.182l.328-.588Z"/></svg>
        </div>
        <div class="article-rating__item" @click="setRating({value: 1})">
          <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="none"><path fill="var(--fill)" d="M9.153 5.208C10.42 2.936 11.053 1.8 12 1.8c.947 0 1.58 1.136 2.847 3.408l.328.588c.36.646.54.969.82 1.182.28.213.63.292 1.33.45l.636.144c2.46.556 3.689.835 3.982 1.776.292.94-.546 1.92-2.223 3.882l-.434.507c-.476.557-.715.836-.822 1.18-.107.345-.071.717.001 1.46l.066.677c.253 2.616.38 3.925-.386 4.506-.766.582-1.918.051-4.22-1.009l-.597-.274c-.654-.302-.981-.452-1.328-.452-.347 0-.674.15-1.329.452l-.595.274c-2.303 1.06-3.455 1.59-4.22 1.009-.767-.581-.64-1.89-.387-4.506l.066-.677c.072-.743.108-1.115 0-1.46-.106-.344-.345-.623-.821-1.18l-.434-.507c-1.677-1.961-2.515-2.942-2.223-3.882.293-.941 1.523-1.22 3.983-1.776l.636-.144c.699-.158 1.048-.237 1.329-.45.28-.213.46-.536.82-1.182l.328-.588Z"/></svg>
        </div>
      </div>
      <div class="article-rating-total">
        <div class="article-rating-total__top">
          <p class="article-rating-total__count">{{ average }}</p>
          <div class="article-rating-total__icon">
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="none"><path fill="var(--fill)" d="M9.153 5.208C10.42 2.936 11.053 1.8 12 1.8c.947 0 1.58 1.136 2.847 3.408l.328.588c.36.646.54.969.82 1.182.28.213.63.292 1.33.45l.636.144c2.46.556 3.689.835 3.982 1.776.292.94-.546 1.92-2.223 3.882l-.434.507c-.476.557-.715.836-.822 1.18-.107.345-.071.717.001 1.46l.066.677c.253 2.616.38 3.925-.386 4.506-.766.582-1.918.051-4.22-1.009l-.597-.274c-.654-.302-.981-.452-1.328-.452-.347 0-.674.15-1.329.452l-.595.274c-2.303 1.06-3.455 1.59-4.22 1.009-.767-.581-.64-1.89-.387-4.506l.066-.677c.072-.743.108-1.115 0-1.46-.106-.344-.345-.623-.821-1.18l-.434-.507c-1.677-1.961-2.515-2.942-2.223-3.882.293-.941 1.523-1.22 3.983-1.776l.636-.144c.699-.158 1.048-.237 1.329-.45.28-.213.46-.536.82-1.182l.328-.588Z"/></svg>
          </div>
        </div>
        <p class="article-rating-total__title">Общий рейтинг</p>
      </div>
    </div>
  </div>
</template>

<script>
import { useRecipesRatingStore } from "../stores/recipes-rating";

export default {
  name: 'ArticleRating',
  props: {
    view: {
      type: String,
      default: () => 'list'
    },
    id: {
      type: String,
      required: true,
    },
  },
  setup() {
    return {
      store: useRecipesRatingStore()
    }
  },
  created() {
    this.store.getRating({id: this.id});
  },
  computed: {
    isLoading() {
      return this.store.isLoading;
    },
    isActive() {
      return +this.store.ratings[this.id]?.average > 0 || false;
    },
    userSet() {
      return this.store.userRatings[this.id];
    },
    average() {
      return Math.round((this.store.ratings[this.id]?.average || 0) * 10) / 10;
    }
  },
  methods: {
    setRating({ value }) {
      this.store.setRating({id: this.id, value})
    },
    cancelRating() {
      this.store.cancelRating({id: this.id})
    },
  }
}
</script>

<style lang="scss">
  @import '../scss/base/includes.scss';

  .article-rating
  {
    &_state
    {
      &_load
      {
        pointer-events: none;
      }
    }
    &__body
    {
      display: flex;

      @include fluid('gap', 20px, 20px, 20px, 20px);
    }
    &__list
    {
      display: flex;
      flex-direction: row-reverse;

      background: $colorAliceBlue;

      @include fluid('padding', 12px 22px, 12px 22px, 12px 22px, 12px 22px);
      @include fluid('border-radius', 48px, 48px, 48px, 48px);
    }
    &__item
    {
      padding: 0 2px;

      cursor: pointer;

      --fill: #{ rgba($colorSilverSand, .5) };

      & svg
      {
        display: block;

        @include fluid('width', 24px, 24px, 24px, 24px);
      }
      &:hover
      {
        --fill: #{ $colorSeaBuckthorn };

        ~ *
        {
          --fill: #{ $colorSeaBuckthorn };
        }
      }
    }
    &-total
    {
      &__top
      {
        display: flex;

        align-items: center;
        @include fluid('gap', 4px, 4px, 4px, 4px);
      }
      &__count
      {
        font-family: $CeraRoundPro;
        font-weight: 700;

        margin: 0;

        color: $colorEndeavour;

        @include fluid('font-size', 22px, 22px, 22px, 22px);
        @include fluid('line-height', 28px, 28px, 28px, 28px);
      }
      &__icon
      {
        & svg
        {
          display: block;

          --fill: #{ $colorSilverSand };
          @include fluid('width', 24px, 24px);

          .article-rating_state_active &
          {
            --fill: #{ $colorSeaBuckthorn };
          }
        }
      }
      &__title
      {
        font-family: $CeraRoundPro;
        font-weight: 500;

        margin: 0;

        color: $colorEndeavour;

        @include fluid('font-size', 14px, 14px, 14px, 14px);
        @include fluid('line-height', 16px, 16px, 16px, 16px);
        @include fluid('margin-top', 4px, 4px, 4px, 4px);
      }
    }
    &-grade
    {
      display: flex;

      align-items: center;
      @include fluid('gap', 8px, 8px);

      &__body
      {
        display: flex;

        background: $colorAliceBlue;

        align-items: center;
        @include fluid('gap', 4px, 4px);
        @include fluid('padding', 12px 24px, 12px 24px);
        @include fluid('border-radius', 48px, 48px);
      }
      &__count
      {
        font-family: $CeraRoundPro;
        font-weight: 700;

        margin: 0;

        columns: $colorEndeavour;
        @include fluid('font-size', 16px, 16px);
        @include fluid('line-height', 20px, 20px);
      }
      &__icon
      {
        & svg
        {
          display: block;

          --fill: #{ $colorSeaBuckthorn };
          @include fluid('width', 24px, 24px);
        }
      }
      &__remove
      {
        display: flex;

        padding: 0;

        cursor: pointer;

        border: none;
        border-radius: 50%;
        outline: none;
        background: $colorWhite;
        box-shadow: 0 4px 8px 0 rgba($colorEndeavour, .2);

        align-items: center;
        justify-content: center;
        @include fluid('width', 40px, 40px);
        @include fluid('height', 40px, 40px);

        & svg
        {
          display: block;

          @include fluid('width', 14px, 14px);
        }
        &:active
        {
          transform: translate(1px, 1px);
        }
      }
    }
    &-value
    {
      position: relative;

      display: flex;

      align-items: center;
      @include fluid('gap', 2px, 4px);

      &:before,
      &:after
      {
        position: absolute;
        top: 0;
        bottom: 0;

        width: 1px;
        margin: auto 0;

        content: '';

        background: rgba($colorCyan, .2);

        @include fluid('height', 16px, 16px);
      }
      &:before
      {
        @include fluid('left', -8px, -21px);
      }
      &:after
      {
        @include fluid('right', -8px, -21px);
      }
      &__count
      {
        font-family: $CeraRoundPro;
        font-weight: 700;

        margin: 0;

        color: $colorMariner;

        @include fluid('font-size', 14px, 18px);
        @include fluid('line-height', 24px, 24px);
      }
      &__icon
      {
        & svg
        {
          display: block;

          --fill: #{ $colorSilverSand };
          @include fluid('width', 20px, 20px);

          .article-rating_state_active &
          {
            --fill: #{ $colorSeaBuckthorn };
          }
        }
      }
    }
    &-list
    {
      display: flex;

      align-items: center;
      @include fluid('gap', 4px, 4px);

      &__count
      {
        font-family: $CeraRoundPro;
        font-weight: 700;

        margin: 0;

        color: $colorMariner;

        @include fluid('font-size', 12px, 14px, 14px, 14px);
        @include fluid('line-height', 24px, 24px, 24px, 24px);
      }
      &__icon
      {
        --fill: #{ $colorSilverSand };

        & svg
        {
          display: block;

          @include fluid('width', 20px, 20px);
        }

        .article-rating_state_active &
        {
          --fill: #{ $colorSeaBuckthorn };
        }
      }
      &:before,
      &:after
      {
        width: 1px;

        content: '';

        opacity: .2;
        background: $colorCyan;

        @include fluid('height', 16px, 16px);
      }
      &:before
      {
        @include fluid('margin-right', 4px, 4px);
      }
      &:after
      {
        @include fluid('margin-left', 4px, 4px);
      }
    }
  }

</style>
